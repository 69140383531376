// Models
import { EEffortLevel } from 'models'
import { IWorkoutLog } from 'services/workoutModel/@types'

// React
import { FC } from 'react'

// Libs
import momentBR from 'lib/moment'

// Misc
import { cn } from 'utils/helpers/classess'
import { handleTimeInterval } from 'utils/functions'

// Components
import { IconButton } from 'heeds-ds'

export type Props = {
  item: IWorkoutLog
  onClickComment?: (comment: string) => void
}

const Mobile: FC<Props> = ({ item, onClickComment }) => {
  const { workout_name, start_time, end_time, effort_perception, comments } =
    item

  return (
    <div
      className={cn(
        'mb-4 flex w-full items-start justify-between rounded-[15px] border',
        'border-b-border-input bg-surface p-4',
      )}
    >
      <div>
        <p className="mb-2 text-copy3 font-semibold text-text-secondary">
          {workout_name}
        </p>
        <p className="mb-1 text-copy3 text-text-subdued first-letter:capitalize">
          {momentBR(start_time).format('ddd, DD [de] MMMM [de] YY')}
        </p>
        <p className="mb-1 text-copy3 text-text-subdued">
          {momentBR(start_time).format('HH:mm')} às{' '}
          {momentBR(end_time).format('HH:mm')}
        </p>
        <p className="mb-1 text-copy3 text-text-subdued">
          {handleTimeInterval(start_time, end_time)}
        </p>
        <p className="mb-1 text-copy3 text-text-subdued">
          {EEffortLevel[effort_perception]}
        </p>
      </div>
      <div>
        <IconButton
          iconName="textSms"
          onClick={() => comments && onClickComment?.(comments)}
        />
      </div>
    </div>
  )
}

export default Mobile
