// Models
import { IWorkoutRoutine } from 'storage/workoutRoutine/models'
import { TMenuDropdownOption } from 'heeds-ds/src/models'

// React
import { FC, useCallback, useContext, useMemo } from 'react'

// Libraries
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { ThemeContext } from 'styled-components'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { intervalDateToString, getDueDate } from 'utils/helpers/date'
import { prepareWorkoutRoutineToDisplay } from 'filters/workouts'
import { urls } from 'routes/paths'

// Components
import * as Styled from './styled'
import { DropdownMenu, IconButton } from 'heeds-ds'

export type Props = {
  onClick: (routineId: number) => void
  onEdit: (routineId: number) => void
  onRemove: () => void
  routine: IWorkoutRoutine
}

const Desktop: FC<Props> = ({ onClick, onEdit, onRemove, routine }) => {
  const { id: athleteId } = useParams()
  const { name, start_date, end_date, id } = routine
  const theme = useContext(ThemeContext)
  const navigate = useNavigate()
  const { goal, difficulty } = prepareWorkoutRoutineToDisplay(routine)

  const options: TMenuDropdownOption[] = [
    {
      icon: 'edit',
      label: 'Editar',
      onClick: () => onEdit(routine.id),
    },
    {
      icon: 'analytics',
      label: 'Relatórios da rotina',
      onClick: () =>
        navigate(
          generatePath(urls.athleteWorkoutsReport, {
            id: athleteId,
            model_id: id,
          }),
        ),
    },
    {
      color: theme.colors.text.critical,
      icon: 'delete',
      label: 'Excluir',
      onClick: onRemove,
    },
  ]

  const period = useMemo(
    () => intervalDateToString(start_date, end_date),
    [end_date, start_date],
  )

  const handleClick = useCallback(() => onClick(id), [id, onClick])

  return (
    <Styled.Row data-testid="row" onClick={handleClick}>
      <Styled.Cell data-testid="columnName">
        <Styled.Text>{name}</Styled.Text>
      </Styled.Cell>

      <Styled.Cell data-testid="columnPeriod">
        <Styled.Text>{period}</Styled.Text>
      </Styled.Cell>

      <Styled.Cell data-testid="columnDueDate">
        <Styled.Text>{getDueDate(end_date)}</Styled.Text>
      </Styled.Cell>

      <Styled.Cell data-testid="columnGoal">
        <Styled.Text>{goal}</Styled.Text>
      </Styled.Cell>

      <Styled.Cell data-testid="columnDifficulty">
        <Styled.Text>{difficulty}</Styled.Text>
      </Styled.Cell>

      <Styled.Cell onClick={(e) => e.stopPropagation()}>
        <DropdownMenu items={options}>
          <IconButton
            iconName="moreVertical"
            size="small"
            track={buttonClickTracking}
            trackName="open_menu_on_routine_item"
          />
        </DropdownMenu>
      </Styled.Cell>
    </Styled.Row>
  )
}

export default Desktop
