// React
import { ReactNode } from 'react'

// Libraries
import InfiniteScroll from 'react-infinite-scroll-component'

// Misc
import { cn } from '../../utils/classes'

// Components
import Icon from '../../components/UI/Icons'

type TColumns = {
  label: ReactNode | ReactNode[]
  filter?: string
}

export type Props<T> = {
  activeFilter?: string
  bottomDistance?: string
  columns: TColumns[]
  columnsFormat?: string
  columnsGap?: string
  data: T[]
  hasMore: boolean
  height?: string
  listEmptyComponent?: ReactNode | ReactNode[]
  loaderComponent?: ReactNode | ReactNode[]
  onEndReached: () => void
  orderingFilter?: (name: string) => void
  padding?: string
  refreshing?: boolean
  renderItem: (item: T) => ReactNode
  rowsGap?: string
  scrollableDiv?: string
  showHeader?: boolean
}

const CommonList = <T extends object>({
  activeFilter,
  bottomDistance,
  columns,
  columnsFormat,
  columnsGap,
  data,
  hasMore,
  height,
  listEmptyComponent,
  loaderComponent,
  onEndReached,
  orderingFilter,
  padding,
  refreshing,
  renderItem,
  rowsGap,
  scrollableDiv = 'scrollableDiv',
  showHeader = true,
}: Props<T>) => {
  const hasData = data.length > 0

  return (
    <div id="scrollableDiv" className="min-h-0 grow overflow-y-auto">
      {showHeader && (
        <div
          className={cn(
            'sticky top-0 grid w-full cursor-pointer border border-solid border-border-input bg-surface-hovered p-0',
            columnsFormat,
            columnsGap,
          )}
        >
          {columns.map((column, index) => (
            <div
              key={index}
              className={cn('flex select-none content-center gap-4', padding)}
              onClick={() => orderingFilter?.(column?.filter || '')}
            >
              <h3 className="m-0 truncate text-left font-roboto text-copy4 font-bold text-text-subdued">
                {column.label}
              </h3>
              {column?.filter && (
                <Icon
                  iconName={
                    activeFilter === column.label ? 'unfoldMore' : 'unfoldMore'
                  }
                  size={16}
                />
              )}
            </div>
          ))}
        </div>
      )}
      {!refreshing && hasData && (
        <InfiniteScroll
          className={cn('flex flex-col', rowsGap, bottomDistance)}
          dataLength={data.length}
          next={onEndReached}
          hasMore={hasMore}
          loader={loaderComponent}
          scrollThreshold="200px"
          scrollableTarget={scrollableDiv}
          height={height}
        >
          {data.map((item) => renderItem(item))}
        </InfiniteScroll>
      )}
      {refreshing && loaderComponent}
      {!refreshing && !hasData && listEmptyComponent}
    </div>
  )
}

export default CommonList
