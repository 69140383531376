// Models
import { ISetToExerciseInput, IWorkoutSetInput } from 'models'

// Libraries
import * as yup from 'yup'

// Misc
import { dateValidationSchema } from './general'
import {
  testIsWorkoutSetWithExercises,
  testHasMoreThanOneExercise,
  testIsGreaterThanStartDate,
  testIsPositiveNumber,
  tranformNaN,
} from './@utils'

const _exerciseSpecialSetSchema = yup
  .object()
  .shape({
    cadence: yup
      .number()
      .transform(tranformNaN)
      .positive('O valor deve ser maior que 0.')
      .nullable(),
    repetitions: yup.string().nullable(),
    comments: yup.string().nullable(),
    weight: yup
      .string()
      .test('isPositive', 'O valor deve ser maior que 0.', testIsPositiveNumber)
      .nullable(),
  })
  .notRequired()

const _setToExerciseSchema = yup.object().shape({
  cadence: yup.string().nullable(),
  weight: yup.string().nullable(),
  repetitions: yup.string().nullable(),
  comments: yup.string().nullable(),
})

const _compoundField = yup.object().shape({
  value: yup.string().nullable(),
  measure: yup.string().nullable(),
})

const _workoutSetSchema = yup.object().shape({
  name: yup.string().nullable(),
  quantity: yup.string().nullable(),
  interval: _compoundField.nullable(),
  exercises: yup.array(_setToExerciseSchema),
})

const _workoutModelSchema = yup.object().shape({
  name: yup.string().required('Campo obrigatório'),
  comments: yup.string().nullable(),
  workout_set: yup.lazy((value: Record<string, IWorkoutSetInput>) => {
    if (value && Object.keys(value).length > 0) {
      const newEntries = Object.keys(value).reduce(
        (acc, val) => ({ ...acc, [val]: _workoutSetSchema }),
        {},
      )

      return yup.object().shape(newEntries).required()
    }
    return yup.mixed().notRequired()
  }),
})

export const workoutModelsSchema = yup.object().shape({
  models: yup
    .array(_workoutModelSchema)
    .test('someExercise', '', testIsWorkoutSetWithExercises)
    .min(1),
})

export const ExerciseSchema = yup.object().shape({
  name: yup.string().required('Nome do exercício e obrigatório'),
  file: yup
    .mixed()
    .required('Escolha um arquivo')
    .test('is-file-or-string', 'Arquivo inválido', (value) => {
      if (typeof value === 'string') {
        return /\.(jpg|jpeg|png|gif|webp|mp4|mkv|mov|avi)$/i.test(value)
      }
      return value instanceof File
    }),
})

export const workoutRoutineSchema = yup.object().shape({
  id: yup.number().transform(tranformNaN).nullable(),
  name: yup.string().required('Digite o nome'),
  difficulty: yup.string().notRequired().nullable(),
  goal: yup.string().notRequired().nullable(),
  start_date: dateValidationSchema.notRequired().nullable(),
  end_date: dateValidationSchema
    .notRequired()
    .nullable()
    .test(
      'greaterThan',
      'A data final deve ser maior que a inicial',
      testIsGreaterThanStartDate,
    ),
  comments: yup.string(),
})

export const workoutSpecialSetSchema = yup.object().shape({
  exercises: yup.lazy((value: Record<string, ISetToExerciseInput>) => {
    if (value !== null || (value && Object.keys(value).length > 0)) {
      const newEntries = value
        ? Object.keys(value).reduce(
            (acc, val) => ({
              ...acc,
              [val]: _exerciseSpecialSetSchema,
            }),
            {},
          )
        : {}

      return yup
        .object()
        .shape(newEntries)
        .required()
        .test('hasMoreThanOne', '', testHasMoreThanOneExercise)
    }
    return yup.mixed().notRequired()
  }),
  comments: yup.string().nullable(),
  interval: yup
    .string()
    .test('isPositive', 'O valor deve ser maior que 0.', testIsPositiveNumber)
    .nullable(),
  quantity: yup
    .number()
    .transform(tranformNaN)
    .positive('O valor deve ser maior que 0.')
    .nullable(),
})
