// Models
import IStore from 'lib/redux/models'
import {
  IWorkoutModelData,
  IWorkoutModelState,
} from 'storage/workoutModel/models'
import { IWorkoutListModelPayload } from 'services/workoutModel/@types'

// React
import { FC, useCallback, useContext, useEffect, useState } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

// Misc
import { generatePath, useNavigate } from 'react-router-dom'
import { useDebounceFunction, useFilters, useMediaQuery, useModal } from 'hooks'
import {
  triggerClearWorkoutModels,
  triggerDeleteWorkoutsModel,
  triggerListWorkoutModelTemplate,
} from 'storage/workoutModel/duck'
import { urls } from 'routes/paths'

// Components
import * as Styled from './styled'
import { CommonList, InputSearch } from 'heeds-ds'
import { LibraryHeader, Modal, WorkoutModelTemplateItem } from 'components'
import Loader from 'components/lists/WorkoutModelTemplateItem/loader'

// Constants
const COLUMNS_GRID =
  'grid-cols-[repeat(3,minmax(20rem,1fr))minmax(7rem,0.33fr)]'

const COLUMNS_HEADERS = [
  { label: 'Nome do modelo' },
  { label: 'Data de criação' },
  { label: 'Criado por' },
  { label: '' },
]

const MODAL_DELETE_CONTENT = {
  title: 'Excluir modelo',
  description:
    'Você está excluindo um modelo de treino, essa ação não poderá ser desfeita. Não se preocupe, as rotinas dos alunos com esse modelo não serão afetadas. Deseja prosseguir?',
  secondaryButton: {
    name: 'Voltar',
    cancel: false,
  },
}

const page_size = 20

const LibraryWorkoutModelTemplate: FC = () => {
  const { workoutModelsTemplates: modelList, loading } = useSelector<
    IStore,
    IWorkoutModelState
  >((state) => state.workoutModel)
  const { filters, setFilter } = useFilters<IWorkoutListModelPayload>(
    window.location.search,
  )
  const { breakpoints } = useContext(ThemeContext)
  const { openModal, isVisible, closeModal } = useModal()

  const dispatch = useDispatch()
  const isTablet = useMediaQuery(`(min-width: ${breakpoints.tablet}px)`)
  const navigate = useNavigate()

  const [selected, setSelected] = useState<IWorkoutModelData>()

  const handleRemoveWorkoutModel = () => {
    selected &&
      dispatch(
        triggerDeleteWorkoutsModel({
          model_pk: selected.id,
          isATemplate: true,
        }),
      )
    closeModal()
  }

  const handleEndReached = () => {
    if (modelList && !loading && modelList?.next) {
      dispatch(
        triggerListWorkoutModelTemplate({
          ...filters,
          page_size,
        }),
      )
    }
  }

  const renderModelItem = useCallback(
    (item: IWorkoutModelData) => {
      const handleEdit = () => {
        dispatch(triggerClearWorkoutModels())
        navigate(
          generatePath(urls.libraryWorkoutModelCreateEdit, { id: item.id }),
        )
      }
      return (
        <WorkoutModelTemplateItem
          key={item.id}
          modelTemplate={item}
          onClick={handleEdit}
          onEdit={handleEdit}
          onRemove={() => {
            setSelected(item)
            openModal('delete-modal')
          }}
        />
      )
    },
    [dispatch, navigate, openModal],
  )

  const handleReloadAthletes = useCallback(
    (newFilters: IWorkoutListModelPayload) => {
      const query = {
        ...newFilters,
        page_size,
      }
      dispatch(triggerListWorkoutModelTemplate(query))
    },
    [dispatch],
  )

  const debouncedLoad = useDebounceFunction<
    (newFilters: IWorkoutListModelPayload) => void
  >(handleReloadAthletes, 1000)

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filters = setFilter('name', event.target.value, true)
    debouncedLoad(filters)
  }

  useEffect(() => {
    dispatch(triggerListWorkoutModelTemplate({ page_size }))
  }, [dispatch])

  return (
    <Styled.Container>
      <LibraryHeader page="Treinos">
        <InputSearch
          placeholder="Pesquisar"
          scale="small"
          onChange={onSearchChange}
          value={filters?.name || ''}
        />
      </LibraryHeader>
      {modelList?.results && modelList?.results?.length > 0 ? (
        <CommonList<IWorkoutModelData>
          bottomDistance={isTablet ? 'mb-0' : 'mb-24'}
          columns={COLUMNS_HEADERS}
          columnsFormat={COLUMNS_GRID}
          data={modelList?.results || []}
          hasMore={false}
          loaderComponent={<Loader />}
          onEndReached={handleEndReached}
          padding="py-4 pr-4 pl-6"
          renderItem={renderModelItem}
          showHeader={isTablet}
        />
      ) : (
        <Styled.EmptyContainer>
          <Styled.EmptyText>
            Você ainda não cadastrou nenhum treino novo.
          </Styled.EmptyText>
        </Styled.EmptyContainer>
      )}
      {isVisible === 'delete-modal' && (
        <Modal
          {...MODAL_DELETE_CONTENT}
          icon="delete"
          maxWidth="53rem"
          onClose={() => setSelected(undefined)}
          primaryButton={{
            name: 'Sim, quero excluir',
            cancel: true,
            onClick: handleRemoveWorkoutModel,
          }}
        />
      )}
    </Styled.Container>
  )
}

export default LibraryWorkoutModelTemplate
