// React
import { FC, ReactNode, useEffect, useState } from 'react'

// Libraries
import { useNavigate } from 'react-router-dom'

// Misc
import { urls } from 'routes/paths'

// Components
import * as Styled from './styled'
import { Tabs } from 'heeds-ds'
// Constants
const STEPS = ['Anamneses', 'Treinos', 'Exercícios']

export interface IAthleteOutletContext {
  id: string
  isEditing: boolean
  navigateToNextTab: (to?: string) => void
  navigateToPreviousTab: (to?: string) => void
  blockNavigation: (to?: string) => void
}

type pageType = 'Anamneses' | 'Treinos' | 'Exercícios'

type Props = {
  children: ReactNode | ReactNode[]
  page: pageType
}

const LibraryHeader: FC<Props> = ({ children, page = 'Anamneses' }) => {
  const navigate = useNavigate()

  const [selectedTab, setSelectedTab] = useState<pageType>(page)

  useEffect(() => {
    switch (selectedTab) {
      case 'Anamneses':
        return navigate(urls.libraryAnamneseTemplates)
      case 'Treinos':
        return navigate(urls.LibraryWorkoutModels)
      case 'Exercícios':
        return navigate(urls.LibraryExerciseTemplate)
    }
  }, [navigate, selectedTab])

  return (
    <Styled.ContentHeader data-testid="library-header-container">
      <Tabs
        active={selectedTab}
        distanceOfBar="6"
        fontSize="copy3"
        setActive={(tab) => setSelectedTab(tab as pageType)}
        tabs={STEPS}
      />
      <Styled.SearchContainer>{children}</Styled.SearchContainer>
    </Styled.ContentHeader>
  )
}

export default LibraryHeader
