// Models
import { IFinancialSubscription } from 'storage/financialSubscription/models'
import { IInstallment } from 'services/financial/@types'
import { TPaymentPayload } from 'filters/financial/models'
import { TPlanPeriodicity } from 'models'
import IStore from 'lib/redux/models'

// React
import { FC, useContext, useLayoutEffect, useMemo, useState } from 'react'

// Libraries
import { Navigate, useParams, useSearchParams } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

// Misc
import { BRL } from 'lib/currency'
import { cn } from 'utils/helpers/classess'
import {
  triggerCreatePayment,
  triggerLoadInstallments,
  triggerLoadMoreInstallments,
} from 'storage/financial/duck'
import { triggerGetFinancialSubscription } from 'storage/financialSubscription/duck'
import { triggerLoadAthleteOverview } from 'storage/athleteOverview/duck'
import { urls } from 'routes/paths'
import { useFilters, useMediaQuery, useMenu, useModal } from 'hooks'

// Components
import * as Block from 'blocks/dashboard/financial'
import { Button, Chip, CommonList, Icon, Loading } from 'heeds-ds'
import {
  FinancialInstallmentItem,
  ModalPayment,
  ModalSendReminder,
  LoaderFinancialInstallmentItem,
} from 'components'

// Constants
const FILTERS = [
  { label: '30 dias', param: 'time_interval', value: 'monthly' },
  { label: '90 dias', param: 'time_interval', value: 'quarterly' },
  { label: '180 dias', param: 'time_interval', value: 'semiannual' },
  { label: '365 dias', param: 'time_interval', value: 'yearly' },
  { label: 'Pendente', param: 'status', value: '0' },
  { label: 'A Vencer', param: 'status', value: '1' },
  { label: 'Pago', param: 'status', value: '2' },
  { label: 'Cancelado', param: 'status', value: '3' },
]

const COLUMNS = [
  { label: 'Plano' },
  { label: 'Valor da mensalidade' },
  { label: 'Data de pagamento' },
  { label: 'Data de vencimento' },
  { label: 'Forma de pagamento' },
  { label: 'Status' },
  { label: '' },
]

const page_size = 20

interface IFilters {
  time_interval?: TPlanPeriodicity
  status?: 0 | 1 | 2 | 3
}

const FinancialAthlete: FC = () => {
  const { id = '' } = useParams()
  const { loading: loadingInstallments, installments } = useSelector(
    (state: IStore) => state.financial,
  )
  const { loading: loadingPlans } = useSelector(
    (state: IStore) => state.athletePlans,
  )
  const {
    error: errorSubscription,
    loading: loadingSubscription,
    subscription,
  } = useSelector((state: IStore) => state.financialSubscription)
  const {
    error: errorOverview,
    loading: loadingOverview,
    overview,
  } = useSelector((state: IStore) => state.athleteOverview)
  const [searchParams] = useSearchParams()
  const { breakpoints } = useContext(ThemeContext)
  const { setPagename } = useMenu()
  const { closeModal, isVisible, openModal } = useModal()
  const { filters, isValueIncluded, toggleFilter } =
    useFilters<IFilters>(searchParams)
  const dispatch = useDispatch()
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.desktop}px)`)

  const [selected, setSelected] = useState<IInstallment>()

  const hasActiveFilters = useMemo(
    () =>
      FILTERS.some((filter) => {
        return isValueIncluded?.(filter.param, filter.value)
      }),
    [isValueIncluded],
  )
  const [showFilters, setShowFilters] = useState(hasActiveFilters)

  const renderItem = (item: IInstallment) => {
    const openPaymentModal = () => {
      setSelected(item)
      openModal('financial-athlete-payment-modal')
    }
    const openReminderModal = () =>
      openModal('financial-athlete-reminder-modal')

    return (
      <FinancialInstallmentItem
        installment={item}
        key={item.id}
        openPaymentModal={openPaymentModal}
        openReminderModal={openReminderModal}
      />
    )
  }

  const onClickFilter = (param: string, value: string) => {
    const newFilters = toggleFilter(param, value, true)
    handleReloadInstallments(newFilters)
  }

  const handleEndReached = () => {
    if (!loadingInstallments && installments?.next && overview) {
      const query = {
        ...filters,
        athlete_pk: overview.id,
        page: installments.next,
        page_size,
      }
      dispatch(triggerLoadMoreInstallments(query))
    }
  }

  const handleReloadInstallments = (newFilters: IFilters) => {
    if (overview) {
      const query = {
        ...newFilters,
        athlete_pk: overview.id,
        page_size,
      }
      dispatch(triggerLoadInstallments(query))
    }
  }

  const handlePaymentInstallment = (payload: TPaymentPayload) => {
    if (selected) {
      const successCallback = () => {
        handleReloadInstallments(filters)
        closeModal()
      }
      dispatch(
        triggerCreatePayment({
          ...payload,
          value: BRL(payload.value).value,
          installment: selected.id,
          successCallback,
        }),
      )
    }
  }

  useLayoutEffect(() => {
    const subscriptionId = Number(id)

    const successCallback = (subscription: IFinancialSubscription) => {
      dispatch(triggerLoadInstallments({ athlete_pk: subscription.athlete }))
      dispatch(triggerLoadAthleteOverview(subscription.athlete))
    }

    dispatch(
      triggerGetFinancialSubscription({ id: subscriptionId, successCallback }),
    )
  }, [dispatch, id])

  useLayoutEffect(() => {
    setPagename('Financeiro')
    return () => setPagename('Dashboard')
  }, [setPagename])

  if (
    id &&
    subscription &&
    subscription?.id === Number(id) &&
    (errorSubscription || errorOverview) === '404'
  ) {
    return <Navigate to={urls.financial} replace />
  }

  if (loadingOverview || loadingPlans || loadingSubscription) {
    return <Loading active />
  }

  return (
    <div className="flex flex-1 flex-col gap-6 p-6 xl:gap-6 xl:pb-0 xl:pl-10 xl:pr-12 xl:pt-10">
      <Block.FinancialAthleteHeader />

      <div
        className={cn(
          'flex flex-1 flex-col rounded-[8px] border-border-input',
          'xl:border xl:bg-surface xl:shadow-sm',
        )}
      >
        <div className="flex w-full flex-col-reverse items-center gap-4 pb-6 xl:flex-row xl:gap-8 xl:px-6 xl:py-4">
          {isDesktop && (
            <p className="text-copy1 font-semibold leading-9 text-text">
              Histórico
            </p>
          )}

          <Button
            className="ml-auto"
            onClick={() => setShowFilters((currentValue) => !currentValue)}
            size="xsmall"
            variation="outlined"
          >
            <Icon iconName="filter" />
            Filtro
          </Button>
        </div>

        {(showFilters || hasActiveFilters) && (
          <div className="no-scrollbar flex flex-wrap gap-2 pb-2  xl:px-6">
            {FILTERS.map((filter) => {
              const isActive = isValueIncluded?.(filter.param, filter.value)
              return (
                <Chip
                  active={isActive}
                  key={filter.label}
                  label={filter.label}
                  onClick={() => onClickFilter(filter.param, filter.value)}
                  scale="small"
                />
              )
            })}
          </div>
        )}

        {installments?.count ? (
          <CommonList<IInstallment>
            columns={COLUMNS}
            columnsFormat="grid-cols-[repeat(6,1fr)68px]"
            data={installments?.results || []}
            hasMore={!!installments?.next}
            loaderComponent={<LoaderFinancialInstallmentItem />}
            onEndReached={handleEndReached}
            padding="py-3 px-4"
            renderItem={renderItem}
            scrollableDiv="dashboard-content"
            showHeader={isDesktop}
          />
        ) : (
          <div className="flex flex-1">
            <p className="m-auto text-copy4 text-text-subdued">
              {Object.values(filters).length
                ? 'Nenhum dado encontrado.'
                : 'Ainda não há dados para mostrar nessa área.'}
            </p>
          </div>
        )}
      </div>

      {isVisible === 'financial-athlete-payment-modal' && (
        <ModalPayment
          onConfirm={handlePaymentInstallment}
          installment={selected}
        />
      )}

      {isVisible === 'financial-athlete-reminder-modal' && (
        <ModalSendReminder
          athleteName={overview?.name}
          onConfirm={(payload) => console.log(payload)}
        />
      )}
    </div>
  )
}

export default FinancialAthlete
