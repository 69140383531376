// Models
import { EPlanModelToPortuguese, EPlanPeriodicityToPortuguese } from 'models'
import IStore from 'lib/redux/models'

// React
import { FC, Fragment, useMemo } from 'react'

// Libraries
import { screens } from 'tailwindcss/defaultTheme'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { cn } from 'utils/helpers/classess'
import { currencyMask } from 'utils/masks'
import { intervalDateToString } from 'utils/helpers/date'
import {
  triggerActivateSubscription,
  triggerDeactivateSubscription,
} from 'storage/financialSubscription/duck'
import { triggerLoadAthleteOverview } from 'storage/athleteOverview/duck'
import { triggerLoadInstallments } from 'storage/financial/duck'
import { triggerToastSuccess } from 'storage/general/duck'
import { useMediaQuery, useModal } from 'hooks'

// Components
import { Avatar, Button, IconButton } from 'heeds-ds'
import { Modal, StatusTag } from 'components'

// Constants
const STATUS_PROPS = {
  active: {
    button: 'Desativar Aluno',
    modal: {
      description:
        'O status do aluno ficará como desativado para o sistema. Você poderá reativar esse aluno a qualquer momento. Deseja prosseguir?',
      title: 'Desativar aluno',
      confirmTitle: 'Desativar',
    },
    toast: 'desativado',
  },
  inactive: {
    button: 'Ativar Aluno',
    modal: {
      description: `Tem certeza que deseja reativar este aluno?`,
      title: 'Reativar aluno',
      confirmTitle: 'Ativar',
    },
    toast: 'ativado',
  },
  invited: {
    button: 'Ativar Aluno',
    modal: {
      description: `Tem certeza que deseja ativar este aluno?`,
      title: 'Ativar aluno',
      confirmTitle: 'Ativar',
    },
    toast: 'ativado',
  },
}

const FinancialAthleteHeader: FC = () => {
  const { id = '' } = useParams()
  const { overview } = useSelector((state: IStore) => state.athleteOverview)
  const { subscription } = useSelector(
    (state: IStore) => state.financialSubscription,
  )
  const { closeModal, openModal, isVisible } = useModal()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isDesktop = useMediaQuery(`(min-width: ${screens.lg})`)

  const status = useMemo(() => overview?.status ?? 'inactive', [overview])

  const handleChangeStatus = () => {
    const successCallback = () => {
      dispatch(triggerLoadAthleteOverview(Number(id)))
      dispatch(triggerLoadInstallments({ athlete_pk: Number(id) }))
      dispatch(
        triggerToastSuccess({
          customTitle: 'Sucesso',
          message: `Aluno ${overview?.name ?? ''} foi ${
            STATUS_PROPS[status].toast
          }.`,
        }),
      )
    }
    if (subscription) {
      const payload = {
        id: subscription.id,
        successCallback,
      }

      dispatch(
        status === 'active'
          ? triggerDeactivateSubscription(payload)
          : triggerActivateSubscription(payload),
      )
    }

    closeModal()
  }

  const hasExtraWorkouts =
    subscription && Boolean(subscription?.plans_extras?.length)

  return (
    <Fragment>
      <div className="flex w-full items-center gap-6">
        {isDesktop && (
          <IconButton
            iconName="arrowBack"
            onClick={() => navigate(-1)}
            size="large"
            track={buttonClickTracking}
            trackName="back_to_athlete_subscriptions"
          />
        )}

        <div
          className={cn(
            'flex w-full flex-col-reverse gap-4 rounded-[8px] border border-border-input bg-surface p-4',
            'xl:flex-row xl:items-center xl:justify-between',
          )}
        >
          <div className="flex items-center gap-4">
            <Avatar name={overview?.name ?? ''} scale="small" />

            <div className="flex flex-col gap-1">
              <p className="text-copy3 font-bold leading-7">
                {overview?.name ?? ''}
              </p>
              <p className="text-copy3 text-text-subdued">
                {subscription &&
                  `${
                    isDesktop ? 'Vigência do Plano:' : ''
                  } ${intervalDateToString(
                    subscription.start_date,
                    subscription.end_date ?? null,
                  )}`}
              </p>
            </div>

            {!isDesktop && (
              <IconButton
                cancel={status === 'active'}
                className="ml-auto"
                iconName={status === 'active' ? 'userOff' : 'user'}
                onClick={() => openModal('(de)activate-athlete-modal')}
                track={buttonClickTracking}
                trackName="open_athlete_activate_or_deactivate_modal"
              />
            )}
          </div>

          {status !== 'active' && <StatusTag status={status} />}
        </div>

        {isDesktop && (
          <div className="flex min-w-[300px] justify-between rounded-[8px] border border-border-input bg-surface px-4 py-[14px]">
            <div className="flex flex-col gap-1">
              {overview && (
                <Fragment>
                  <p className="text-copy4 text-text-subdued">
                    {overview.plan_model &&
                      EPlanModelToPortuguese[overview.plan_model]}
                  </p>

                  <p className="text-copy4 text-text-subdued">
                    {overview.plan_periodicity &&
                      EPlanPeriodicityToPortuguese[overview.plan_periodicity]}
                  </p>

                  {hasExtraWorkouts && (
                    <p className="text-copy4 text-text-subdued">
                      Treinos Extras
                    </p>
                  )}
                </Fragment>
              )}
            </div>

            <div className="flex flex-col items-end justify-center gap-2">
              <p className="text-copy2 font-bold">Valor do Plano</p>

              <p className="text-copy1 font-semibold">
                {currencyMask(String(subscription?.total_price ?? 0))}
              </p>
            </div>
          </div>
        )}

        {isDesktop && (
          <Button
            cancel={status === 'active'}
            onClick={() => openModal('(de)activate-athlete-modal')}
            size="xsmall"
            track={buttonClickTracking}
            trackName="open_athlete_activate_or_deactivate_modal"
          >
            {STATUS_PROPS[status].button}
          </Button>
        )}
      </div>

      {isVisible === '(de)activate-athlete-modal' && (
        <Modal
          {...STATUS_PROPS[status].modal}
          icon="warning"
          maxWidth="530px"
          primaryButton={{
            name: `Sim, quero ${status === 'active' ? 'desativar' : 'ativar'}`,
            onClick: handleChangeStatus,
          }}
          secondaryButton={{ name: 'Cancelar' }}
        />
      )}
    </Fragment>
  )
}

export default FinancialAthleteHeader
