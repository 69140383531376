// Models
import { IBaseState } from 'lib/redux/models'
import { IPaginatedResponse } from 'models/api'
import { IFinancialSubscriptionList } from 'services/financialSubscription/@types'

export enum EFinancialSubscriptionActionTypes {
  CLEANUP = 'FINANCIAL_SUBSCRIPTION/CLEANUP',
  FAILURE = 'FINANCIAL_SUBSCRIPTION/FAILURE',
  FULFILL = 'FINANCIAL_SUBSCRIPTION/FULFILL',
  REQUEST = 'FINANCIAL_SUBSCRIPTION/REQUEST',
  SUCCESS = 'FINANCIAL_SUBSCRIPTION/SUCCESS',
  ACTIVATE = 'FINANCIAL_SUBSCRIPTION/ACTIVATE',
  CREATE = 'FINANCIAL_SUBSCRIPTION/CREATE',
  DEACTIVATE = 'FINANCIAL_SUBSCRIPTION/DEACTIVATE',
  GET = 'FINANCIAL_SUBSCRIPTION/GET',
  LIST = 'FINANCIAL_SUBSCRIPTION/LIST',
  LIST_MORE = 'FINANCIAL_SUBSCRIPTION/LIST_MORE',
  SUCCESS_LOAD_MORE = 'FINANCIAL_SUBSCRIPTION/SUCCESS_LOAD_MORE',
  UPDATE = 'FINANCIAL_SUBSCRIPTION/UPDATE',
}

export interface IFinancialSubscriptionState
  extends IBaseState,
    Omit<IPaginatedResponse, 'results'> {
  subscription?: IFinancialSubscription
  subscriptions?: Array<IFinancialSubscriptionList>
}

export interface IFinancialSubscription {
  id: number
  auto_renew?: boolean
  discount?: number
  frequency?: string
  due_date?: string
  end_date?: string
  start_date: string
  status: string
  athlete: number
  personal: number
  plan: number
  plans_extras?: number[] | null
  spread?: boolean
  total_price?: number
}

export type TSubscriptionStatus = 'active' | 'inactive'
